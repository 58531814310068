<template>
  <v-btn @click="signout">
    <v-icon class="pr-4">
      fa-sign-out-alt
    </v-icon>
    sign out
  </v-btn>
</template>

<script>
import SignOut from '../models/signOut'

export default {
  created() {
    const self = this
    this.$bus.$on('signOut', () => {
      self.signout()
    })
  },
  methods: {
    async signout() {
      const result = await SignOut.signOut(this)
      // console.log(`signout reuslt:: ${result}`)
      if (result === true) {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
